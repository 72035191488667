import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import * as Styled from "./styled";

const sectionNames = {
  home: "Início",
  solutions: "Soluções",
  testimonials: "Depoimentos",
  partners: "Parceiros",
  contact: "Contato",
};

const NavBar: React.FC = () => {
  const [current, setCurrent] = useState("home");
  const [menu, setMenu] = useState(false);

  const handleClickMenu = (key: string) => {
    setCurrent(key);
    setMenu((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.keys(sectionNames);
      const scrollPosition = window.scrollY + window.innerHeight / 4;

      for (const section of sections) {
        const el = document.getElementById(section);
        if (el) {
          const top = el.offsetTop;
          const height = el.offsetHeight;
          if (scrollPosition >= top && scrollPosition <= top + height) {
            setCurrent(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Styled.Container>
        <Styled.NavBar>
          <Styled.Logo>
            <img src={"/logo.svg"} alt="Finlytech Logo" />
          </Styled.Logo>
          <Styled.MenuContainer>
            <Styled.Menu>
              {Object.entries(sectionNames).map(([key, name]) => (
                <Styled.MenuItem
                  key={key}
                  className={current === key ? "selected" : ""}
                >
                  <Link
                    to={key}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                    onClick={() => setCurrent(key)}
                  >
                    {name}
                  </Link>
                </Styled.MenuItem>
              ))}
            </Styled.Menu>
            {/* <Button type="primary">Login</Button> */}
          </Styled.MenuContainer>
        </Styled.NavBar>
      </Styled.Container>
      <Styled.ContainerMobile>
        <Styled.NavBarMobile>
          <Styled.ContainerNavBarMobile>
            <Styled.Logo>
              <img src={"/logo.svg"} alt="Finlytech Logo" />
            </Styled.Logo>
            <Styled.HamburguerMenu
              onClick={() => setMenu((prevState) => !prevState)}
            >
              <Styled.Line className={menu ? "active" : ""} />
              <Styled.Line className={menu ? "active" : ""} />
              <Styled.Line className={menu ? "active" : ""} />
            </Styled.HamburguerMenu>
          </Styled.ContainerNavBarMobile>
          {menu && (
            <Styled.MenuMobile>
              {Object.entries(sectionNames).map(([key, name]) => (
                <Styled.MenuItemMobile
                  key={key}
                  className={current === key ? "selected" : ""}
                >
                  <Link
                    to={key}
                    smooth={true}
                    duration={1000}
                    onClick={() => handleClickMenu(key)}
                  >
                    {name}
                  </Link>
                </Styled.MenuItemMobile>
              ))}
            </Styled.MenuMobile>
          )}
        </Styled.NavBarMobile>
      </Styled.ContainerMobile>
    </>
  );
};

export default NavBar;
