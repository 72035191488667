import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

export const SliderContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledSlider = styled(Slider)`
  .slick-slide {
    display: flex;
    justify-content: center;
  }
`;

export const Slide = styled.div`
  outline: none;
`;

export const Card = styled.div`
  width: 250px;
  height: 140px;
  margin-right: 10px;
  background-color: white;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;