import React from "react";
import { ContainerArrows } from "./styled";

type CustomArrowProps = {
  onClick: () => void;
  styles: React.CSSProperties;
};

const CustomNextArrow = (props: CustomArrowProps) => (
  <ContainerArrows onClick={props.onClick} style={{
    right: 0,
    transform: "translate(-100%, -50%)"
  }}>
    <svg
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37.9917" cy="38.9332" r="38" fill="#22326E" />
      <path
        d="M28.8347 26.1022H41.9497L54.3327 39.5832L41.9497 53.0642H28.8347L41.2787 39.5832L28.8347 26.1022Z"
        fill="#C9E437"
      />
    </svg>
  </ContainerArrows>
);

export default CustomNextArrow;
