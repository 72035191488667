import React from "react";
import { Typography } from "antd";
import FinlyCard from "./FinlyCard";
import FinlyTax from "./FinlyTax";
import FinlyPrev from "./FinlyPrev";
import * as Styled from "./styled";

const { Title } = Typography;

const Solutions: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.ContainerText>
        <Title level={1}>
          Nossas plataformas inovadoras oferecem as automações necessárias para
          <span>
            {" "}
            gerar eficiência, simplificar processos e alcançar resultados
            impressionantes.
          </span>
        </Title>
      </Styled.ContainerText>
      <Styled.ContainerStatics>
        <Styled.Statics>
          <Title level={1}>
            1700<span>+</span>
          </Title>
          Clientes atendidos
        </Styled.Statics>
        <Styled.Statics>
          <Title level={1}>
          425Mi<span>+</span>
          </Title>
          Créditos Trib. recuperados
        </Styled.Statics>
        <Styled.Statics>
          <Title level={1}>
            7<span>+</span>
          </Title>
          Tecnologias exclusivas
        </Styled.Statics>
      </Styled.ContainerStatics>
      <Styled.CardContainer>
        <FinlyCard />
        <FinlyTax />
        <FinlyPrev />
      </Styled.CardContainer>
    </Styled.Container>
  );
};

export default Solutions;
