import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as Styled from './styled';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2, 
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

type CarouselSliderProps = {
  images: string[];
};

const CarouselSlider = ({ images }: CarouselSliderProps) => {
  return (
    <Styled.SliderContainer>
      <Styled.StyledSlider {...settings}>
        {images.map((image, index) => (
          <Styled.Slide key={index}>
            <Styled.Card>
              <Styled.Image src={image} alt={`Slide ${index}`} />
            </Styled.Card>
          </Styled.Slide>
        ))}
      </Styled.StyledSlider>
    </Styled.SliderContainer>
  );
};

export default CarouselSlider;
