import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/contact-background.png");
  background-position: center;
  .ant-btn {
    margin-top: -30px;
    max-width: 700px;
    width: 70vw;
    border-radius: 200px;
    font-weight: 700;
    padding: 0 40px;
    font-size: 1rem;
    height: 60px;
    border-radius: 100px;
    background-color: #c9e437;
    color: #252f64;
    &:not(:disabled):hover {
      background: #a6bb2d;
      color: #252f64;
    }
  }
`;

export const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 900px;
  margin: 70px 0 80px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const Line = styled.div`
  width: 3px;
  height: 400px;
  background-color: #c9e437;
  margin: 0 20px;
  @media (max-width: 1024px) {
    max-width: 400px;
    width: 40%;
    height: 3px;
    margin: 20px 0;
  }
`;

export const ContainerSocial = styled.div`
  display: flex;
  max-width: 450px;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  .ant-typography {
    margin: 0;
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.7;
  }
  div {
    font-weight: 400;
  }
  h1 {
    font-weight: 700;
  }
  @media (max-width: 1024px) {
    align-items: center;
    text-align: center;
    margin-left: 0;
  }
`;

export const SocialIcon = styled.img`
  width: 40px;
  margin-left: 20px;
  @media (max-width: 1024px) {
    margin: 0 20px;
    width: 50px;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
  }
`;

export const Logo = styled.img`
  padding-right: 150px;
  width: 250px;
  @media (max-width: 1024px) {
    padding-right: 0;
  }
`;
