import styled from "styled-components";

export const Container = styled.div`
  margin: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    margin: 70px 0 0;
  }
`;

export const ContainerFuncionality = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 0 70px 0 70px;
  background-color: #22326e;
  color: #fff;
  @media (max-width: 1200px) {
    border-radius: 0;
    width: 100%;
    max-width: 1200px;
  }
`;

export const TitleFuncionality = styled.div`
  display: flex;
  padding: 15px 0;
  margin: 20px 0 0 40px;
  h3 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
  }
  img {
    display: none;
  }
  @media (max-width: 1200px) {
    margin: 0;
    justify-content: center;
    border-radius: 0;
    width: 100%;
    h3 {
      text-align: center;
    }
    img {
      display: block;
      margin-left: 10px;
      width: 15px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
`;

export const FuncionalityList = styled.ul`
  font-size: 1rem;
  font-weight: 400;
  margin: 20px 40px 40px;
  @media (max-width: 1200px) {
    display: none;
    max-width: 80%;
    font-size: 1rem;
  }
`;

export const ContainerNotebook = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0 0 0 160px;
  &:before {
    content: "";
    position: absolute;
    width: 35%;
    max-width: 340px;
    height: 100%;
    max-height: 675px;
    background-color: #c9e437;
    border-radius: 0 0 0 160px;
    z-index: -1;
  }
  h1 {
    display: none;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    margin-bottom: 80px;
    h1 {
      display: block;
      font-size: 3rem;
      font-weight: 700;
      color: #22326e;
      span {
        font-size: 3.5rem;
        color: #c9e437;
        font-weight: 900;
      }
    }
    &:before {
      height: 55vw;
      transform: translateX(-80%);
    }
  }
`;

export const ContainerNotebookPrev = styled.div`
  display: flex;
  align-items: center;
  &:before {
    content: "";
    position: absolute;
    width: 35%;
    max-width: 340px;
    height: 100%;
    max-height: 750px;
    background-color: #c9e437;
    border-radius: 0 0 160px 0;
    z-index: -1;
  }
  h1 {
    display: none;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    margin-bottom: 80px;
    h1 {
      display: block;
      font-size: 3rem;
      font-weight: 700;
      color: #22326e;
      span {
        font-size: 3.5rem;
        color: #c9e437;
        font-weight: 900;
      }
    }
    &:before {
      height: 55vw;
      transform: translateX(-80%);
    }
  }
`;

export const Notebook = styled.img`
  max-width: 600px;
  width: 50vw;
  padding: 0 20px;
`;

export const ContainerCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: #22326e;
    margin: 0;
    span {
      font-size: 3.5rem;
      color: #c9e437;
      font-weight: 900;
    }
  }
  h2 {
    font-size: 1.1rem;
    color: #22326e;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1200px) {
    align-items: center;
    h1 {
      display: none;
    }
    h2 {
      font-size: 1.3rem;
      text-align: center;
      max-width: 80%;
    }
  }
`;

export const Logo = styled.img`
  position: absolute;
  transform: translateX(10px);
  width: 45px;
`;
