import styled from "styled-components";

export const Container = styled.header`
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c143ada;
  backdrop-filter: blur(10px);
  @media (max-width: 1024px) {
    display: none;
  }
  .ant-btn {
    font-weight: 700;
    padding: 0 40px;
    font-size: 1rem;
    height: 55px;
    border-radius: 100px;
    background-color: #c9e437;
    color: #252f64;
    &:not(:disabled):hover {
      color: #252f64;
    }
    &:not(:disabled):active {
      color: #252f64;
      background-color: #c9e437;
    }
  }
`;

export const NavBar = styled.nav`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Menu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 10px;
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
`;

export const MenuItem = styled.li`
  margin: 0 25px;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: color 0.5s;
  &:hover {
    color: #e4fa6b;
  }
  &.selected {
    color: #c9e437;
  }
`;

export const ContainerMobile = styled.header`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
    background-color: #0c1439;
  }
`;

export const HamburguerMenu = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  align-items: center;
  max-width: 1200px;
  .active {
    background-color: #c9e437;
  }
`;

export const Line = styled.div`
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  margin: 3px 0;
  border-radius: 5px;
  transition: background-color 0.5s;
`;

export const NavBarMobile = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
`;

export const MenuMobile = styled.ul`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 15px 30px;
  .ant-btn {
    margin: 10px 0 0;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 100px;
    background-color: #c9e437;
    color: #252f64;
    &:not(:disabled):hover {
      color: #252f64;
    }
    &:not(:disabled):active {
      color: #252f64;
      background-color: #c9e437;
    }
  }
`;

export const ContainerNavBarMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MenuItemMobile = styled.li`
  padding: 10px 0;
  font-size: 1rem;
  width: 100%;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: color 0.5s;
  border-bottom: 1px solid white;
  &:hover {
    font-weight: 700;
  }
  &.selected {
    font-weight: 700;
  }
`;