import styled from 'styled-components';

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1000px;
  line-height: 160px;
  text-align: center;
  width: 100%;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  h1 {
    color: #22326e;
    font-size: 3rem;
    font-weight: 700;
  } 
  h2 {
    color: #22326e;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: italic;
  }

  .name {
    color: #22326e;
    font-size: 2rem;
    font-weight: 700;
  }

  .role {
    color: #22326e;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .profile {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 15px;
    object-fit: cover;
  } 
  @media (max-width: 600px) {
    h1 {
      font-size: 2rem;
    } 
    h2 {
      font-size: 1.2rem;
    }
    .name {
      font-size: 1.5rem;
    }
    .role {
      font-size: 1rem;
    }
  }
`;

export const ContainerArrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  img {
    margin-left: 2px;
  }
`;