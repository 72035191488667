import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ContainerText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #c9e437;
  z-index: -1;
  margin-top: -50px;
  padding: 80px 0 150px 0;
  .ant-typography {
    color: #252f64;
    font-size: 2rem;
    max-width: 800px;
    font-weight: 400;
    padding-bottom: 50px;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 40px;
    .ant-typography {
      max-width: 90%;
    }
  }
`;

export const ContainerStatics = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: -130px;
  max-width: 1200px;
  background-color: #252f64;
  border-radius: 50px;
  color: #ffffff;
  padding: 50px 0;
  @media (max-width: 1024px) {
    border-radius: 0;
  }
`;

export const Statics = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  h1 {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 800;
    line-height: 1;
    margin: 0;
    span {
      color: #c9e437;
    }
  }
  @media (max-width: 764px) {
    padding: 20px 0;
    font-size: 1rem;
    h1 {
      font-size: 2rem;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-top: 100px;
`;
