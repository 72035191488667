import React from "react";
import { Button, Typography } from "antd";
import * as Styled from "./styled";
import Link from "antd/es/typography/Link";

const { Paragraph, Title } = Typography;

type ContactProps = {
  setOpen: (open: boolean) => void;
};

const Contact = ({ setOpen }: ContactProps) => {
  return (
    <Styled.Container>
      <Button type="primary" onClick={() => setOpen(true)}>
        Solicitar Contratação
      </Button>
      <Styled.SubContainer>
        <Styled.Logo src="/logo.svg" alt="Logo" />
        <Styled.Line />
        <Styled.ContainerSocial>
          <Styled.Social>
            <img src="/phone.svg" alt="telefone" />
            <Styled.TextContainer>
              <Paragraph>+55 31 2115-4112​</Paragraph>
            </Styled.TextContainer>
          </Styled.Social>
          <Styled.Social>
            <img src="/gps-location.svg" alt="localização" />
            <Styled.TextContainer>
              <Title>BELO HORIZONTE/MG​</Title>
              <Paragraph>Avenida Alvares Cabral, 1345​</Paragraph>
              <Paragraph>8º andar · Lourdes</Paragraph>
            </Styled.TextContainer>
          </Styled.Social>
          <Styled.Social>
            <img src="/gps-location.svg" alt="localização" />
            <Styled.TextContainer>
              <Title>SÃO PAULO/SP​</Title>
              <Paragraph>Rua Arizona, 491, 23º andar · Brooklin</Paragraph>
            </Styled.TextContainer>
          </Styled.Social>
          <Styled.SocialMedia>
            <Link
              href="https://www.linkedin.com/company/finly-tech/"
              target="_blank"
            >
              <Styled.SocialIcon src="/linkedin-logo.svg" alt="Linkedin" />
            </Link>
            <Link href="https://www.instagram.com/finlytech/" target="_blank">
              <Styled.SocialIcon src="/instagram.svg" alt="Instagram" />
            </Link>
            <Link
              href="https://api.whatsapp.com/send/?phone=5531997908777&text&type=phone_number&app_absent=0"
              target="_blank"
            >
              <Styled.SocialIcon src="/whatsapp-logo.svg" alt="Whatsapp" />
            </Link>
          </Styled.SocialMedia>
        </Styled.ContainerSocial>
      </Styled.SubContainer>
    </Styled.Container>
  );
};

export default Contact;
