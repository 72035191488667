import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import * as Styled from "../FinlyCard/styled";

const { Title } = Typography;

const FinlyPrev: React.FC = () => {
  const [showContent, setShowContent] = useState(true);

  const handleClick = () => {
    if (window.innerWidth < 1200) {
      setShowContent(!showContent);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1200) {
        setShowContent(false);
      } else {
        setShowContent(true);
      }
    });
  }, []);

  return (
    <Styled.Container>
      <Styled.ContainerNotebookPrev>
        <Styled.Notebook
          src="/finlytax-notebook.png"
          alt="aplicação finlycard"
        />
        <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo" />
        Prev<span>.</span></Title>
      </Styled.ContainerNotebookPrev>
      <Styled.ContainerCardText>
        <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo" />
        Prev<span>.</span></Title>
        <Title level={2}>
          O software FINLY PREV realiza uma <span>auditoria eletrônica automatizada
          nas folhas de pagamento</span>, fornecendo relatórios em tempo real. Esses
          relatórios abrangem novos créditos tributários aproveitáveis de
          imediato e quaisquer correções necessárias.
        </Title>
        <Styled.ContainerFuncionality>
          <Styled.TitleFuncionality>
          <Title level={3}>Funcionalidades</Title>
          <img src="/arrow.svg" alt="arrow" onClick={handleClick}
          style={{
            transform: showContent ? 'rotate(0deg)' : 'rotate(180deg)'
          }}
          />
          </Styled.TitleFuncionality>
          <Styled.FuncionalityList style={{
            display: showContent || window.innerWidth >= 1200 ? 'block' : 'none'
          }}>
            <li>
              O Finly Prev conta com módulos especializados para a análise de
              folha de pagamentos, como a análise de rubricas e a comparação
              automatizada com dados do e-Social. 
            </li>
            <li>
              Capacidade de classificar e categorizar as rubricas, o sistema
              garante que os tributos sejam calculados corretamente e em
              conformidade com os padrões tributários estabelecidos. 
            </li>
            <li>
              Identificar possíveis inconsistências nas informações fornecidas,
              permitindo que medidas corretivas sejam tomadas antes que haja
              problemas com as autoridades fiscais. 
            </li>
            <li>
              Automatizar processos complexos de análise tributária, onde o
              sistema permitirá que os profissionais de RH e contabilidade
              foquem em outras tarefas importantes
            </li>
          </Styled.FuncionalityList>
        </Styled.ContainerFuncionality>
      </Styled.ContainerCardText>
    </Styled.Container>
  );
};

export default FinlyPrev;
