import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import * as Styled from "./styled";

const { Title } = Typography;

const FinlyTax: React.FC = () => {
  const [showContent, setShowContent] = useState(true);

  const handleClick = () => {
    if (window.innerWidth < 1200) {
      setShowContent(!showContent);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1200) {
        setShowContent(false);
      } else {
        setShowContent(true);
      }
    });
  }, []);

  return (
    <Styled.Container>
      <Styled.ContainerCardText>
        <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo"/>
        Tax<span>.</span></Title>
        <Title level={2}>
          A nova solução FINLY TAX é <span>uma ferramenta tecnológica altamente
          disruptiva</span> que possibilita a entrega do compliance Fiscal/Tributário
          totalmente automatizado, em Real Time.Gerando economia de tempo e
          dinheiro para a sua empresa!
        </Title>
        <Styled.ContainerFuncionality>
          <Styled.TitleFuncionality>
          <Title level={3}>Funcionalidades</Title>
          <img src="/arrow.svg" alt="arrow" onClick={handleClick}
          style={{
            transform: showContent ? 'rotate(0deg)' : 'rotate(180deg)'
          }}
          />
          </Styled.TitleFuncionality>
          <Styled.FuncionalityList style={{
            display: showContent || window.innerWidth >= 1200 ? 'block' : 'none'
          }}>
            <li>Auditoria eletrônica completa das obrigações acessórias</li>
            <li>
              Auditoria Eletrônica em tempo real da EFD Contribuições e fontes
              pagadoras
            </li>
            <li>
              Leitura completa e Auditoria Eletrônica da ECF além dos
              cruzamentos das fontes pagadoras
            </li>
            <li>Auditoria eletrônica em Real Time do ICMS IPI</li>
            <li>
              Leitura e Auditoria completa de todas as Notas Fiscais e MFD
              (Cupons eletrônicos de venda)
            </li>
          </Styled.FuncionalityList>
        </Styled.ContainerFuncionality>
      </Styled.ContainerCardText>
      <Styled.ContainerNotebook>
      <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo"/>
        Tax<span>.</span></Title>
        <Styled.Notebook
          src="/finlytax-notebook.png"
          alt="aplicação finlycard"
        />
      </Styled.ContainerNotebook>
    </Styled.Container>
  );
};

export default FinlyTax;
