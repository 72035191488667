import React from "react";
import { Form, Modal, Button, Input, message } from "antd";
import axios from "axios";

type ModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type FormValues = {
  nome: string;
  telefone: string;
  email: string;
  cupom?: string;
};

const ModalComponent = ({ open, setOpen }: ModalProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const messageOpen = (content: string, type: 'success' | 'error') => {
    messageApi.open({
      type,
      content,
    });
  };


  const handleOk = async (values: FormValues) => {
    const url = "https://api.finlycard.com/api/proposta";
    values.telefone = values.telefone.replace(/[^0-9]/g, "");
    
    try {
      await axios.post(url, values);
      messageOpen("Solicitação enviada com sucesso", "success");
      setOpen(false);

    } catch (error) {
      messageOpen("Erro ao enviar solicitação, tente novamente!", "error");
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title={`Deixe-nos conhecê-lo melhor! Preencha o formuçãrio 
        abaixo e embarque na jornada da excelência conosco`}
        onCancel={handleCancel}
        footer={[]}
        className="modal"
      >
        {contextHolder}
        <Form 
          layout="vertical"
          onFinish={handleOk}>
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: "Preencha esse campo" }]}
            hasFeedback
          >
            <Input placeholder="Insira seu nome" />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="telefone"
            rules={[
              { required: true, message: "Preencha esse campo" },
              {
                message: "Preencha no formato valido +55 XX XXXXX-XXXX",
                min: 14,
                max: 17,
                warningOnly: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="+55 XX XXXXX-XXXX" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { required: true, message: "Preencha esse campo" },
              {
                type: "email",
                message: "Insira um email válido",
                warningOnly: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Insira seu email" />
          </Form.Item>
          <Form.Item
            label="Cupom"
            name="cupom"
            >
            <Input placeholder="Insira seu cupom" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <Button type="primary" htmlType="submit">
              Enviar Solicitação
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalComponent;
