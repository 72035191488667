import React from "react";
import { Typography } from "antd";
import { ContainerCard, ContainerText } from "./styled";
import Rating from "./Rating";

const { Title, Text } = Typography;

type CardTestimonialsProps = {
  title: string;
  text: string;
  name: string;
  image: string;
  role: string;
  stars: number;
};

const CardTestimonials: React.FC<CardTestimonialsProps> = ({
  title,
  name,
  text,
  image,
  role,
  stars,
}: CardTestimonialsProps) => (
    <ContainerCard>
      <ContainerText>
        <Title level={1}>{title}</Title>
        <img src="/apostrophe.svg" alt="apostofre" />
        <Title level={2}>{text}</Title>
        <img
          src={image}
          alt="People"
          className="profile"
        />
        <Text className="name">{name}</Text>
        <Text className="role">{role}</Text>
        <Rating nota={stars} />
      </ContainerText>
    </ContainerCard>
  );


export default CardTestimonials;
