import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import * as Styled from "./styled";

const { Title } = Typography;

const FinlyCard: React.FC = () => {
  const [showContent, setShowContent] = useState(true);

  const handleClick = () => {
    if (window.innerWidth < 1200) {
      setShowContent(!showContent);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1200) {
        setShowContent(false);
      } else {
        setShowContent(true);
      }
    });
  }, []);

  return (
    <Styled.Container>
      <Styled.ContainerNotebook>
        <Styled.Notebook
          src="/finlycard-notebook.png"
          alt="aplicação finlycard"
        />
        <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo"/>
        Card<span>.</span></Title>
      </Styled.ContainerNotebook>
      <Styled.ContainerCardText>
        <Title level={1}><span>/</span>
        <Styled.Logo src="/logo-blue.svg" alt="logo"/>
        Card<span>.</span></Title>
        <Title level={2}>
          Solução inteligente que <span>trata as conciliações, auditoria e um módulo
          específico de gestão das vendas de cartões</span>, gerando eficiência
          operacional, empoderamento e agilidade nas tomadas de decisões para o
          seu negócio.
        </Title>
        <Styled.ContainerFuncionality>
          <Styled.TitleFuncionality>
          <Title level={3}>Funcionalidades</Title>
          <img src="/arrow.svg" alt="arrow" onClick={handleClick}
          style={{
            transform: showContent ? 'rotate(0deg)' : 'rotate(180deg)'
          }}
          />
          </Styled.TitleFuncionality>
          <Styled.FuncionalityList style={{
            display: showContent || window.innerWidth >= 1200 ? 'block' : 'none'
          }}>
            <li>
              Dashboard resumido: Dados de vendas, pagamentos, indicadores-chave
              do seu negócio.
            </li>
            <li>
              Validação de transações: Cartão de crédito, débito/pré-pago,
              vouchers e Pix.
            </li>
            <li>
              Auditoria de taxas: Verificação das taxas nas adquirentes,
              bandeiras e modalidades com IA.
            </li>
            <li>
              Relatórios detalhados: Vendas, pagamentos, taxas, exportáveis em
              CSV, PDF, XLSX.
            </li>
            <li>Antecipação de valores.</li>
            <li>
              Integração API: Disponibilização de acesso as informações de
              Venda, Pagamento e Operações financeiras via APIs para comuni cação
              e integração com sistemas e ERPs.
            </li>
          </Styled.FuncionalityList>
        </Styled.ContainerFuncionality>
      </Styled.ContainerCardText>
    </Styled.Container>
  );
};

export default FinlyCard;
