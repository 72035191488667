import React from "react";
import { ContainerRate } from "./styled";

type RatingProps = {
  nota: number;
};

const Rating: React.FC<RatingProps> = ({ nota }) => {
  const completeStars = Array.from({ length: nota }).fill("/star.svg");
  const voidStars = Array.from({ length: 5 - nota }).fill("/star-without.svg");
  const stars = [...completeStars, ...voidStars] as string[];

  return (
    <ContainerRate>
      {stars.map((star, index) => (
        <img key={index} src={star} alt="estrelas"/>
      ))}
    </ContainerRate>
  );
};

export default Rating;
