import React from "react";
import { Typography } from "antd";
import * as Styled from "./styled";
import CarouselSlider from "../Slider";

const { Title } = Typography;

const clients = [
  "/assets/clients/client_001.png",
  "/assets/clients/client_002.png",
  "/assets/clients/client_003.png",
  "/assets/clients/client_004.png",
  "/assets/clients/client_005.png",
  "/assets/clients/client_006.png",
  "/assets/clients/client_007.png",
  "/assets/clients/client_008.png",
  "/assets/clients/client_009.png",
  "/assets/clients/client_010.png",
];

const partners = [
  "/assets/partners/partner_001.png",
  "/assets/partners/partner_002.png",
  "/assets/partners/partner_003.png",
  "/assets/partners/partner_004.png",
  "/assets/partners/partner_005.png",
  "/assets/partners/partner_006.png",
  "/assets/partners/partner_007.png",
  "/assets/partners/partner_008.png",
  "/assets/partners/partner_009.png",
  "/assets/partners/partner_010.png",
];

const Partners: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.SubContainer>
        <Styled.ClientsContainer>
          <Styled.TextContainer>
            <Title level={1}>Nossos Clientes</Title>
          </Styled.TextContainer>
          <CarouselSlider images={clients} />
        </Styled.ClientsContainer>
        <Styled.ClientsContainer>
          <Styled.TextContainer>
            <Title level={1}>Nossos Parceiros</Title>
          </Styled.TextContainer>
          <CarouselSlider images={partners} />
        </Styled.ClientsContainer>
      </Styled.SubContainer>
    </Styled.Container>
  );
};

export default Partners;
