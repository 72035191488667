import React from "react";
import { ContainerArrows } from "./styled";

type CustomArrowProps = {
  onClick: () => void;
  styles: React.CSSProperties;
};



const CustomPrevArrow = (props: CustomArrowProps) => (
  <ContainerArrows onClick={props.onClick} style={{
    transform: "translate(100%, -50%)"
  }}>
    <svg
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37.9917" cy="38.9332" r="38" fill="#22326E" />
      <path
        d="M35.8517 53.0642L23.4687 39.5832L35.8517 26.1022H48.9667L36.5227 39.5832L48.9667 53.0642H35.8517Z"
        fill="#C9E437"
      />
    </svg>
  </ContainerArrows>
);

export default CustomPrevArrow;
