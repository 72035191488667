import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  background-image: url(/background-partners.png);
  background-position: left;
  background-size: cover;
  @media (max-width: 1200px) {
    background-position: 37% 0; 
  }
  `;

export const ClientsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 70px;
  max-width: 400px;
  justify-content: center;
  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
  } div {
    font-size: 1.1rem;
    color: #fff;
  }
  @media (max-width: 1200px) {
    div {
      display: none;
    }
    h1 {
      font-size: 1.8rem;
    }
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 90vw;
    margin: 0 auto;
}
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  width: 220px;
  height: 130px;
  background-color: #fff;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  img {
    max-width: 130px;
    max-height: 100px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    max-width: none;
    max-height: none;
    height: 130px;
    img {
      max-width: 80%;
      max-height: 70%;
    }
  }
`;