import React from "react";
import { Carousel } from "antd";
import CardTestimonials from "./CardTestimonials";
import CustomPrevArrow from "./CardTestimonials/CustomPrevArrow";
import CustomNextArrow from "./CardTestimonials/CustomNextArrow";
import { Container } from "./styled";

const testimonials = [
  {
    title: "Excelência e Importância!",
    text: "“A Finly promoveu um trabalho de excelência e de muita importância para nossa empresa. Conseguimos, através das soluções da Finly, obter informações estratégicas que melhoraram os nossos processos internos com automações e eficiência de caixa. Recomendamos a empresa Finly ao mercado como referência em soluções em compliance fiscal eletrônico”.",
    name: "José Roberto Cezar Salomão",
    image: "/assets/salomao.png",
    role: "Diretor Comercial, Grupo Salomão",
    stars: 5
  },
  {
    title: "Trabalho de Extrema Importância!",
    text: "“A Finly promoveu um trabalho de extrema importância e excelência nas minhas empresas, realizaram as atividades com muita eficiência com tecnologia e auditoria eletrônica, nos trouxe benefícios econômicos e aplicações de melhorias em nossos procedimentos. Recomendamos a Finly Tech como uma empresa especializada e de confiança”.",
    name: "Armando",
    image: "/assets/maurano.png",
    role: "Controller, Grupo Maurano",
    stars: 5
  },
  {
    title: "Serviço de Excelência!",
    text: "“A Finly Tech prestou serviços nas empresas do Grupo Santiago & Cintra e Geo Agri Tecnologia Agrícola com extrema excelência gerando resultados muito positivos, além da implantação de soluções tecnológicas fiscais com a melhoria de processos internos e automação. Recomendamos a Finly pela excelente experiência que tivemos como clientes e da nossa satisfação pelos resultados alcançados”.",
    name: "Diego Figueiredo",
    image: "/assets/geoagri.png",
    role: "Diretor de Controladoria, Geo Agri / Santiago e Cintra",
    stars: 5
  },
  {
    title: "Tecnologia de Excelência!",
    text: "“A Finly Tech prestou serviços para a SERTA TRANSFORMADORES fornecendo as suas plataformas de Compliance Fiscal que nos gerou vários benefícios dentre eles econômicos e operacionais. A Finly é uma empresa de excelência em tecnologia fiscal Tributária”.",
    name: "Gisele Calixto",
    image: "/assets/serta.png",
    role: "Presidente, Serta Transformadores",
    stars: 5
  },
];

const styleArrow: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 20px",
  width: "60px",
  height: "60px",
  position: "absolute",
  zIndex: 1,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
};

const Testimonials: React.FC = () => (
  <Container>
    <Carousel
      infinite={true}
      arrows
      prevArrow={<CustomPrevArrow onClick={() => {}} styles={styleArrow}/>}
      nextArrow={<CustomNextArrow onClick={() => {}} styles={styleArrow}/>}
      style={{
        backgroundImage: 'url("/testimonials-background.svg")',
        backgroundSize: "cover", 
        backgroundPosition: "center",
      }}
    >
      {testimonials.map((testimonial) => (
        <CardTestimonials
          key={testimonial.title}
          title={testimonial.title}
          text={testimonial.text}
          name={testimonial.name}
          image={testimonial.image}
          role={testimonial.role}
          stars={testimonial.stars}
        />
      ))}
    </Carousel>
  </Container>
);

export default Testimonials;