import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Poppins", sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
  }
  #partners {
    .slick-slider {
      overflow: hidden;
    }
  }

  .modal {
    .ant-modal-content {
      border-radius: 20px;
    }
    .ant-modal-title {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 700;
      color: #252f64;
      margin: 20px 0;
    }
    .ant-modal-body {
      padding: 0 20px;
    }
    .ant-btn {
      padding: 10px 40px;
      border-radius: 50px;
      height: auto;
      font-weight: 600;
      color: #22326e;
    }
    .ant-input-affix-wrapper {
      margin-top: 5px;
    }
  }
`;

export default GlobalStyle;