import React, { useState } from "react";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Solutions from "./components/Solutions";
import Testimonials from "./components/Testimonials";
import Partners from "./components/Partners";
import Contact from "./components/Contact";
import Modal from "./components/Modal";
import { Element } from "react-scroll";
import { ConfigProvider } from "antd";


const App: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#c9e437",
            colorPrimaryHover: "#a9c027",
          }
        }
      }}
    >
      <NavBar />
      <Element name="home" id="home">
        <Home setOpen={setOpen} />
      <Modal open={open} setOpen={setOpen}/>
      </Element>
      <Element name="solutions" id="solutions">
        <Solutions />
      </Element>
      <Element name="testimonials" id="testimonials">
        <Testimonials />
      </Element>
      <Element name="partners" id="partners">
        <Partners />
      </Element> 
      <Element name="contact" id="contact">
        <Contact setOpen={setOpen}/>
      </Element>
    </ConfigProvider>
  );
};

export default App;
