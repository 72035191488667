import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  .ant-btn {
    font-weight: 700;
    padding: 0 30px;
    font-size: 1rem;
    height: 55px;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.darkBlue};
    &:hover {
      background: #d0e658;
      color: ${({ theme }) => theme.colors.darkBlue};
    }
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  display: flex;
  padding: 0 0 50px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h1 {
    color: #22326e;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  div {
    color: #22326e;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 40px;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 40px;
    width: 80%;
    align-items: center;
    h1 {
      text-align: center;
    }
    div {
      text-align: center;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  background-image: url(/graphic.svg);
  background-size: 270px;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: right;
  justify-content: left;
  align-items: center;
  padding-right: 100px;
  width: 50%;
  img {
    padding: 0 50px 0 0;
    max-width: 550px;
    max-height: 650px;
    object-fit: cover;
    border-radius: 0 350px 0 0;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const BannerHorizontal = styled.img`
  cursor: pointer;
  width: 90%;
  max-width: 1200px;
  border-radius: 130px 0 130px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    margin-top: -150px;
    width: 80%;
  } 
  @media (max-width: 768px) {
    width: 95%;
    border-radius: 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 60px;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35vw;
  max-height: 530px;
  z-index: -1;
  background-image: url(/contact-background.png);
  background-position: center;
  @media (max-width: 1024px) {
    position: relative;
    height: 200px;
  }
`;
