import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import * as Styled from "./styled";

const { Title, Paragraph } = Typography;

type HomeProps = {
  setOpen: (open: boolean) => void;
};

const Home = ({ setOpen }: HomeProps) => {
  const [isHorizontal, setIsHorizontal] = useState<boolean>(true);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1024) {
        setIsHorizontal(true);
      } else {
        setIsHorizontal(false);
      }
    });
  }, []);

  return (
    <Styled.Container>
      <Styled.Background />
      <Styled.BannerHorizontal
        src={`/campanha-${isHorizontal ? "banner.png" : "banner-mobile.png"
          }`}
        alt="campanha"
        onClick={() => setOpen(true)}
      />
      <Styled.InfoContainer>
        <Styled.ImageContainer>
          <img src="/people-talk.jpg" alt="people talking" />
        </Styled.ImageContainer>
        <Styled.TextContainer>
          <Title level={1}>
            Um encontro entre inovação, eficiência e inteligência artificial, em
            prol da simplificação do compliance?
          </Title>
          <Paragraph>
            <span>A Finly prova que é possível!</span> Simplificamos o
            compliance através da inteligência artificial-IA, com soluções
            inovadoras e de alta performance. Tudo para facilitar o processo
            para você!
          </Paragraph>
          <Button type="primary" onClick={() => setOpen(true)}>
            Solicitar contratação
          </Button>
        </Styled.TextContainer>
      </Styled.InfoContainer>
    </Styled.Container>
  );
};

export default Home;
